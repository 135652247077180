String.prototype.format = function() {
  var args = arguments || []
  return this.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] != 'undefined' ? args[number] : match
  })
}

String.prototype.isNumeric = function() {
  return !isNaN(this) && !isNaN(parseFloat(this))
}

Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
}

Date.prototype.format = function(fmt) { 
  var o = {
      'M+': this.getMonth() + 1,
      'd+': this.getDate(),
      'h+': this.getHours(),
      'm+': this.getMinutes(),
      's+': this.getSeconds(),
      'q+': Math.floor((this.getMonth() + 3) / 3),
      'S': this.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) 
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt)) 
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return fmt
}

Array.prototype.swap = function(x, y) {
  var b = this[x]
  this[x] = this[y]
  this[y] = b
  return this
}

Array.prototype.group = function(groupKey) {
  return Object.values(this.reduce((accumulate, elem) => {
    let key
    if (typeof groupKey === 'function') {
      key = groupKey(elem)
    } else {
      key = elem[groupKey]
    }
    accumulate[key] = accumulate[key] || []
    accumulate[key].push(elem);
    return accumulate;
  }, {}))
}
