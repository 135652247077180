import style from './style.scss'
import '../override'

window.addEventListener('unhandledrejection', event => {
  // base.alert(event.reason.msg || '發生錯誤')
  // if (event.reason.code === 103)
  //   toLogin()
  // else
})

document.addEventListener('error', event => {
  if (event.target.localName === 'img' && !event.target.retry) {
    const img = $(event.target)
    switch(img.attr('kd-image')) {
      case 'avatar':
        event.target.src = `${serverPath}/images/iv_main_portrait.png`
        break
    }
    event.target.retry = true
  }
}, true)

window.onload = function() {
  JqueryFormSubmit.middleware.alert = ({ text, title, style }) => Swal.fire({
    title,
    text,
    confirmButtonText: '確認'
  })

  JqueryFormSubmit.middleware.confirm = ({ text, title, style }) => Swal.fire({
    title,
    text,
    showCancelButton: true,
    confirmButtonText: '確認',
    cancelButtonText: '取消',
  }).then(result => {
    if(!result.isConfirmed)
      throw new Error('isNotConfirmed');
  })

  JqueryFormSubmit.middleware.error = message => {
    return Swal.fire({
      icon: 'error',
      title: message,
      allowOutsideClick: false,
      confirmButtonText: '確認'
    })
  }
}
